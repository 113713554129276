/* ############ */
/* ## COMMON ## */
/* ############ */

:root {
    --dark-color: #282828;
    --light-grey-1-color: #828282;
    --light-grey-2-color: #ABABAB;
    --light-grey-3-color: #DEDEDE;
    --orange-color: #FF6600;
}

body {
    margin: 0;
    font-family: "Poppins", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.common-wrapper {
    background-color: #f0f0f0;
}

.common-container {
    padding: 0 4rem;
}

.aboutus-container {
    padding: 0 10rem;
}

.common-h2 {
    font-size: 5rem;
    font-weight: 900;
    margin: 0;
    margin-bottom: 2rem;
    text-align: center;
    margin-top: 8rem;
    margin-bottom: 3rem;
    color: var(--dark-color);
}

.common-p {
    margin: 0;
    font-size: 1.3rem;   
    line-height: 2;         
    letter-spacing: 0.05rem;  
    text-align: center;      
    font-weight: 400;
}

h4 {
    font-size: 1.5rem;
    margin: 0;
    color: #fff;
    font-weight: 700;
    text-align: center;
}

.btn-outline {
    padding: 1.5rem 3rem;
    font-size: 1.2rem;
    margin-top: 4rem;
}

.btn-fill {
    padding: 1.5rem 3rem;
    font-size: 1.2rem;
    background-color: var(--orange-color);
    color: #fff;
    border: 0;
    font-weight: 600;
    margin-top: 4rem;
}

button:hover {
    cursor: pointer;
}

hr {
    border: 1px solid #dbdbdb;
    margin: 1rem 0 4rem 0;
}
  
/* ############### */
/* ## MAIN PAGE ## */
/* ############### */

.hero-section {
    margin: 0;
    padding: 0;
    height: 100vh; 
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-image: url('/src/images/bgHeroSection.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.hero-title {
    font-size: 6rem;
    font-weight: 900;
    color: #fff;
    margin: 0;
    text-align: center;
}

.hero-highlight {
    color: var(--orange-color);
}

.hero-button {
    margin-top: 6rem;
}

.about-section {
    margin: 0;
    padding: 0;
    height: 80vh; 
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-image: url('/src/images/bgAboutUs.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 3rem 0;
}

.about-title {
    margin: 0;
    margin-bottom: 2rem;
}

.about-text {
    width: 60%;
    color: var(--dark-color);
}

.services-section {
    margin: 0;
    padding: 0 10rem;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-image: url('/src/images/bgServicesSection.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.services-title {
    color: #fff;
}

.services-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    padding: 2rem;
}

.service-button {
    margin-bottom: 8rem;
}

.partners-section {
    margin: 0;
    padding: 0 10rem;
    min-height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #fff;
}

.partners-flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 2rem 4rem;
    padding: 4rem 2rem;
}

.partners-button {
    margin-bottom: 8rem;
}

.partner-mainpage-image {
    width: 14.5rem;
}

.blogs-section {
    margin: 0;
    padding: 0 10rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-image: url('/src/images/bgBlogsSection.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.blogs-grid {
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(2, minmax(300px, 1fr));
    margin: 0 auto;
}

.blogs-button {
    margin-bottom: 8rem;
}
  

/* ################################## */
/* ## COMING SOON + NOT FOUND PAGE ## */
/* ################################## */

#blank-page {
    margin: 0;
    padding: 0 10rem;
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-image: url('/src/images/bgAboutUs.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.blank-page-title {
    font-size: 5rem;
    font-weight: 900;
    color: #282828;
    text-align: center;
}

/* ##################### */
/* ## CONTACT US PAGE ## */
/* ##################### */

.contact-us {
    background-color: #f0f0f0;
}

.contact-content {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    margin: 0 5rem;
}

.contact-map {
    flex: 1;
}

.contact-map iframe {
    border: 0;
    width: 100%;
}

.contact-details {
    flex: 1;
    margin: 1rem;
    margin-left: 4rem;
}

.contact-heading {
    font-size: 1.5rem;
    color: var(--dark-color);
    margin: 3rem 0 0 0;
}

.contact-item {
    display: flex;
    align-items: center;
    margin: 0.5rem 0;
}

.contact-item img {
    width: 1.2rem;
    margin-right: 1.5rem;
}

.contact-item p {
    font-size: 1rem;
    text-align: left;
}

/* ####################### */
/* ## OUR PARTNERS PAGE ## */
/* ####################### */

.partners-grid {
    display: grid;
    gap: 5rem;
    grid-template-columns: repeat(2, minmax(300px, 1fr));
    margin: 0 15rem;
}

/* ##################### */
/* ## BLOGS MAIN PAGE ## */
/* ##################### */

.blogs-grid-wrapper {
    margin: 0 5rem;
}

.blogs-grid {
    display: grid;
    gap: 3rem;
    grid-template-columns: repeat(2, minmax(300px, 1fr));
}

/* ################### */
/* ## SERVICES PAGE ## */
/* ################### */

.services-grid-wrapper {
    margin: 0 5rem;
}

.services-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
}

/* ################### */
/* ## MARKDOWN PAGE ## */
/* ################### */

.markdown-container {
    margin: 0 10rem;
    font-family: 'Times New Roman', Times, serif;
}

.markdown-image {
    width: 100%;
    height: 30rem;
    margin-top: 5rem;
    object-fit: cover;
}

.markdown-content {
    background-color: #fff;
    padding: 5rem 5rem;
}

#markdown-wrapper h1 {
    font-size: 2.5rem;
    text-align: left;
    margin: 0;
}

#markdown-wrapper h2 {
    font-size: 1.5rem;
    text-align: left;
    margin: 0;
    margin-bottom: 1rem;
    margin-top: 3rem;
}

#markdown-wrapper h3 {
    font-size: 1.2rem;
    text-align: left;
    margin: 0;
}

#markdown-wrapper p {
    font-size: 1.1rem;
    text-align: left;
    margin: 0;
    margin-bottom: 2rem;
    line-height: 1.5;
}

#markdown-wrapper li {
    font-size: 1.1rem;
    line-height: 1.5;
    margin-bottom: 0.5rem;
}

.markdown-btn-container {
    display: flex;
    justify-content: flex-end;
}

.markdown-btn {
    background-color: #2B2B2B;
    color: #fff;
    font-size: 0.9rem;
    font-weight: 500;
    padding: 1rem 1.5rem;
    margin: 2rem 10rem;
    border: 0;
}

/* ################### */
/* ## ABOUT US PAGE ## */
/* ################### */

.aboutus-title {
    color: var(--dark-color);
    margin-top: 6rem;
    margin-bottom: 3rem;
    font-size: 1.8rem;
}

.values-grid {
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(3, minmax(300px, 1fr));
}

.leads-section {
    padding-bottom: 7rem;
}

.leads-grid {
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(3, minmax(300px, 1fr));
}

.achievements-section {
    padding-bottom: 5rem;
}

.achievements-grid {
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(2, minmax(300px, 1fr));
}

/* Mobile menu styles */
@media only screen and (max-width: 1300px) {

    /* OUR PARTNERS PAGE */

    .partners-grid {
        margin: 0 6rem;
        gap: 4rem;
    }

    /* BLOGS MAIN PAGE */
    
    .blogs-grid-wrapper {
        margin: 0;
    }

    /* SERVICES MAIN PAGE */
    
    .services-grid-wrapper {
        margin: 0;
    }

    /* MARKDOWN PAGE */

    .markdown-container {
        margin: 0 5rem;
    }

    /* ABOUT US PAGE */

    .values-grid {
        grid-template-columns: repeat(2, minmax(300px, 1fr));
    }

    .leads-grid {
        grid-template-columns: repeat(2, minmax(300px, 1fr));
    }

    /* MAIN PAGE */

    .hero-title {
        font-size: 4.5rem;
    }

    .hero-button {
        margin-top: 6rem;
    }

    .blogs-section {
        padding: 0 2.5rem;
    }
    
}

/* Small devices (tablets, 576px and up) */
@media only screen and (max-width: 900px) {

    /* CONTACT US PAGE */

    .contact-content {
        flex-direction: column;
    }

    .contact-map iframe {
        height: 30rem;
        width: 30rem;
    }

    .contact-details {
        margin-left: 1rem !important;
    }

    /* OUR PARTNERS PAGE */
    
    .partners-grid {
        margin: 0;
        grid-template-columns: repeat(2, minmax(200px, 1fr));
        gap: 2rem;
    }

    /* BLOGS MAIN PAGE */
    
    .blogs-grid {
        display: grid;
        gap: 2rem;
        grid-template-columns: repeat(1, minmax(200px, 1fr));
    }

    /* MARKDOWN PAGE */

    .markdown-container {
        margin: 0 2rem;
    }

    /* ABOUT US PAGE */

    .aboutus-container {
        padding: 0 5rem;
    }

    .values-grid {
        grid-template-columns: repeat(1, minmax(250px, 1fr));
    }

    .leads-grid {
        grid-template-columns: repeat(1, minmax(300px, 1fr));
    }

    .achievements-grid {
        grid-template-columns: repeat(1, minmax(300px, 1fr));
    }

    /* MAIN PAGE */

    .hero-title {
        font-size: 3.5rem;
    }

    .blogs-section {
        padding: 0 3rem;
    }
}

/* Extra small devices (phones, < 576px) */
@media only screen and (max-width: 575.98px) {

    /* COMING SOON + NOT FOUND PAGE */
    
    .blank-page-title {
        font-size: 5rem;
        font-weight: 900;
        color: #282828;
    }

    /* CONTACT US PAGE */

    .contact-map iframe {
        height: 20rem;
        width: 20rem;
    }

    /* OUR PARTNERS PAGE */
    
    .partners-grid {
        grid-template-columns: repeat(1, minmax(300px, 1fr));
    }

    /* MARKDOWN PAGE */

    .markdown-image {
        margin: 0;
    }

    .markdown-container {
        margin: 0;
    }

    .markdown-content {
        padding: 2rem;
    }

    .markdown-btn-container {
        justify-content: center;
    }
    
    .markdown-btn {
        margin: 0;
        margin-top: 2rem;
    }

    /* ABOUT US PAGE */

    .aboutus-container {
        padding: 0 2rem;
    }

    .leads-grid {
        grid-template-columns: repeat(1, minmax(300px, 1fr));
    }

    .achievements-grid {
        grid-template-columns: repeat(1, minmax(300px, 1fr));
    }

    /* MAIN PAGE */

    .hero-title {
        font-size: 3rem;
    }

    .blogs-section {
        padding: 0 2rem;
    }

    .about-text {
        width: 75%;
    }

    /* COMMON */

    .common-h2 {
        font-size: 3rem;
    }

    .common-p {
        font-size: 1.1rem;
    }

    .btn-fill {
        font-size: 1rem;
        font-weight: 600;
        padding: 1rem 2rem;
    }

    .common-container {
        padding: 2rem;
    }

    .blank-page-title {
        font-size: 3rem;
    }

    /* SERVICES PAGE */

    .services-grid {
        padding: 0;
    }

    /* BLOG - WRITING */

    #markdown-wrapper h1 {
        font-size: 2rem;
        margin-bottom: 3rem;
    }

    #markdown-wrapper h2 {
        font-size: 1.5rem;
        margin-bottom: 1rem;
    }

    #markdown-wrapper h3 {
        font-size: 1rem;
        margin: 0;
    }

    #markdown-wrapper p {
        font-size: 1rem;
        margin-bottom: 2rem;
    }
}