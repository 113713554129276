/* ########### */
/* ## CARDS ## */
/* ########### */

/* ## CARD - PARTNERS ## */

.partner-card {
    position: relative;
    width: 100%;
    cursor: pointer;
}

.partner-image {
    width: 100%;
    display: block;
}

.partner-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(0, rgba(0, 0, 0, 0.8) 0%, #ff6600f5 100%);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.3s ease;
    z-index: 2;
}

.partner-card:hover .partner-overlay {
    opacity: 1;
}

/* ## CARD - BLOG ## */

.blog-card {
    width: 100%;
    height: 16rem;
    display: flex;
    align-items: center;
    background-color: #ffffff;
    overflow: hidden;
}

.blog-card__image {
    width: 40%; 
    height: 100%;
    object-fit: cover;
}

.blog-card__content {
    height: 100%;
    padding: 2rem;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    overflow: hidden;
}

.blog-card__title {
    font-size: 1.5rem;
    margin: 0;
    margin-bottom: 0.5rem;
    font-weight: 700;
    color: var(--dark-color);
}

.blog-card__text {
    font-size: 0.9rem;
    text-align: left;
    color: var(--light-grey-2-color);
    margin: 0;
    line-height: 1.5;         
    letter-spacing: 0.01rem;  
    font-weight: 400;
}

.blog-card__button {
    background-color: #fff;
    color: var(--orange-color);
    border: 1px solid var(--orange-color);
    padding: 10px 20px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
    align-self: flex-end;
}
  

/* ## CARD - BLOG MAIN ## */

.blog-card-main {
    width: 100%;
    height: 16rem;
    display: flex;
    align-items: center;
    overflow: hidden;
    margin-bottom: 4rem;
    background-color: #fff;
}

.blog-card-main__button {
    background-color: var(--orange-color);
    color: #fff;
    border: 0;
    padding: 10px 20px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
    align-self: flex-end;
}

/* ## CARD - BLOG SERVICE ## */

.service-card {
    display: flex;
    align-items: center;
    background-color: #fff;
    border: 0;
    overflow: hidden;
    width: 100%;
    height: 12rem;
    position: relative;
    padding-right: 2rem;
    transition: background-color 0.3s ease;
}

.service-card::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(135deg, #ff6600b4 0%, rgba(0, 0, 0, 0.5) 100%);
    opacity: 0;
    transition: opacity 0.3s ease;
    z-index: 0;
}

.service-card:hover::after {
    opacity: 1;
}

.service-card:hover {
    cursor: pointer;
    color: white;
}

.service-card-name {
    text-align: left;
    font-weight: 600;
    color: var(--dark-color);
    font-size: 1.4rem;
    flex: 1;
}

.service-card-image {
    width: 40%;
    height: 100%;
    object-fit: cover;
    clip-path: polygon(0 0, 80% 0, 100% 100%, 0 100%);
    margin-right: 1.5rem;
}

.service-card-arrow {
    font-size: 1.8rem;
    color: var(--dark-color);
    transition: transform 0.3s ease, color 0.3s ease;
    padding: 1rem;
}

.service-card:hover .service-card-arrow {
    transform: translateX(5px);
    color: var(--primary-color);
}

/* CARD - DARK SERVICE */

.service-dark-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #2B2B2B; 
    border: 2px solid #474747;
    overflow: hidden;
    width: 30rem;
    height: 10rem; 
}

.service-dark-card:hover {
    cursor: pointer;
}

.service-dark-name {
    text-align: left;
    font-weight: 600;
    color: var(--light-grey-3-color);
    font-size: 1.4rem;
}

.service-dark-image {
    width: 40%;
    height: 100%;
    object-fit: cover;
    clip-path: polygon(0 0, 80% 0, 100% 100%, 0 100%);
    margin-right: 1.5rem;
}

/* CARD - MISSION VISION */

.mv-section {
    margin: 5rem 0;
    padding: 2rem;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23ABABABFF' stroke-width='3' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}

.mv-section__up {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    border-bottom: 2px solid #dedede;
}

.mv-section__down {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;
}

.mv-content h4 {
    color: var(--dark-color);
    text-align: left;
}

.mv-content p {
    color: var(--dark-color);
    font-size: 1rem;
    line-height: 1.6;
    letter-spacing: 0.05rem;
    text-align: justify;
    font-weight: 400;
}

.mv-icon {
    height: 8rem;
    margin: 2rem;
}

/* CARD - VALUES */

.value-card {
    background-color: #fff;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 4rem 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.value-icon {
    height: 8rem;
}

.value-name {
    font-size: 1.1rem;
    margin: 2rem 0 1rem 0;
    text-align: center;
}

.value-text {
    font-size: 0.9rem;
    line-height: 1.6;
    text-align: justify;
}

/* CARD - LEAD */

.lead-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
}

.lead-icon {
    height: 15rem;
    display: block;
}

.lead-name {
    font-size: 1.1rem;
    margin: 2rem 0 0.5rem 0;
    text-align: center;
    color: var(--orange-color);
}

.lead-designation {
    font-size: 0.9rem;
    line-height: 1.6;
    text-align: justify;
}

.lead-overlay {
    position: absolute;
    top: 0;
    width: 15rem;
    height: 15rem;
    background: linear-gradient(0, rgba(0, 0, 0, 0.8) 0%, #ff6600f5 100%);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.3s ease;
    z-index: 2;
}

.lead-card:hover .lead-overlay {
    opacity: 1;
}

/* CARD - ACHEIVEMENT */

.achievement-card {
    background-color: #fff;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 4rem 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.achievement-icon {
    height: 8rem;
}

.achievement-name {
    font-size: 1.1rem;
    margin: 2rem 0 0.5rem 0;
    text-align: center;
    color: var(--orange-color);
}

.achievement-description {
    font-size: 0.9rem;
    line-height: 1.6;
    text-align: justify;
}

/* ######### */
/* ## CTA ## */
/* ######### */

/* ## CTA - HEADER ## */

.cta-header {
    padding: 6rem 0;
    color: var(--dark-color);
    text-align: center;
}

.cta-header__heading {
    color: var(--dark-color);
    margin-bottom: 1.5rem;
}

.cta-header__text {
    color: var(--dark-color);
}
  
/* ## CTA - FOOTER ## */

.cta-footer {
    padding: 10rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.cta-footer__title {
    color: var(--dark-color);
    margin-bottom: 1rem;
    text-align: center;
}

.cta-footer__highlight {
    color: var(--orange-color);
}

.cta-footer__button {
    margin-top: 3rem;
    padding: 1rem 2rem;
    background-color: var(--orange-color);
    color: #fff;
    border: 0;
    font-weight: 700;
    font-size: 1.1rem;
}

.cta-footer__button:hover {
    cursor: pointer;
}

/* ############ */
/* ## NAVBAR ## */
/* ############ */

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #1a1a1a;
    padding: 1.5rem 4rem;
    font-family: Arial, sans-serif;
    font-size: 1.1rem !important;
    position: relative;
}

.navbar__logo {
    height: 40px;
    margin-right: 10px;
    cursor: pointer;
}

.navbar__links {
    display: flex;
    list-style: none;
    gap: 40px;
    align-items: center;
}

.navbar__item {
    position: relative;
}

.navbar__link {
    color: #fff;
    cursor: pointer;
    text-decoration: none;
}

.navbar__link--active::after {
    content: '';
    display: block;
    width: 100%;
    height: 2px;
    background-color: var(--orange-color);
    position: absolute;
    bottom: -5px;
    left: 0;
}

.navbar__button {
    padding: 8px 16px;
    cursor: pointer;
    transition: all 0.3s;
    text-decoration: none;
}

.navbar__button--contact {
  background-color: transparent;
  border: 1px solid var(--orange-color);
  color: var(--orange-color);
  padding: 8px 16px;
  cursor: pointer;
  transition: all 0.3s;
  text-decoration: none;
}

.navbar__button--contact:hover {
  background-color: var(--orange-color);
  color: #fff;
}

.navbar__hamburger {
    display: none;
    flex-direction: column;
    cursor: pointer;
}

.navbar__bar {
    width: 25px;
    height: 3px;
    background-color: #fff;
    margin: 4px 0;
    transition: 0.3s;
    border-radius: 10px;
}

.navbar__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    z-index: 10;
}

/* ############ */
/* ## FOOTER ## */
/* ############ */

.footer {
    background-color: #1a1a1a;
    color: var(--light-grey-1-color);
    padding: 4rem 10rem;
    line-height: 2 !important;
    letter-spacing: 0.1rem !important;
}

.footer__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 2rem;
    margin-bottom: 2rem;
    border-bottom: 1px solid #333;
}

.footer__left {
    max-width: 50%;
}

.footer__logo {
    height: 2rem;
    margin-bottom: 1rem;
}

.footer__text {
    width: 25rem;
    font-size: 1rem;
    text-align: left;
}

.footer__right {
    display: flex;
    gap: 5rem;
}

.footer__heading {
    margin-bottom: 10px;
    font-size: 1rem;
}

.footer__list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.footer__list-item {
    margin: 5px 0;
    cursor: pointer;
    font-size: 14px;
}

.footer__bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
    font-size: 12px;
}

.footer__copyright {
    font-size: 1rem;
    text-align: left;
    font-weight: 500;
    color: var(--light-grey-1-color);
}

.footer__legal-links {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.footer__legal-item {
    margin-left: 2rem;
    cursor: pointer;
    font-size: 0.8rem;
}

/* ############## */
/* ## SECTIONS ## */
/* ############## */

/* HERO SECTION */

#section-hero {
    background-image: url('/src/images/heroBg.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.section-hero__text {
    margin: 0;
    font-size: 5rem !important;
    font-weight: 900;
    color: #fff;
    text-align: center;
}

/* Mobile menu styles */
@media only screen and (max-width: 1300px) {

    /* NAVBAR */

    .navbar__links {
        position: absolute;
        top: 60px;
        left: 0;
        width: 100%;
        background-color: #1a1a1a;
        flex-direction: column;
        align-items: center;
        display: none;
        height: 100vh;
        justify-content: center;
        z-index: 20;
    }
    
    .navbar__links--mobile {
        display: flex;
    }
    
    .navbar__hamburger {
        display: flex;
        z-index: 30;
    }
    
    .navbar__hamburger--toggle .navbar__bar:nth-child(1) {
        transform: rotate(45deg) translate(7px, 7px);
    }
    
    .navbar__hamburger--toggle .navbar__bar:nth-child(2) {
        opacity: 0;
    }
    
    .navbar__hamburger--toggle .navbar__bar:nth-child(3) {
        transform: rotate(-45deg) translate(7px, -7px);
    }
}

@media only screen and (max-width: 900px) {

    /* FOOTER */

    .footer {
        padding: 4rem;
        line-height: 1.5 !important;
        letter-spacing: 0.05rem !important;
    }

    .footer__left {
        width: 50%;
        max-width: 50%;
        margin-bottom: 2rem;
    }

    .footer__text {
        width: 100%;
        font-size: 0.9rem;
    }

    .footer__bottom {
        font-size: 0.8rem;
    }

    .footer__legal-links {
        justify-content: flex-end;
    }

    /* CARD - MISSION VISION */

    .mv-section {
        padding: 0;
        margin: 2rem 0;
    }

    .mv-icon {
        height: 5rem;
    }
}

/* Extra small devices (phones, < 576px) */
@media only screen and (max-width: 575.98px) {

    /* NAVBAR */

    .navbar {
        padding: 1.5rem 2rem;
    }

    .navbar__logo {
        height: 25px;
        margin-right: 10px;
    }

    .navbar__links {
        position: absolute;
        top: 0px;
        left: -40px;
    }

    /* FOOTER */

    .footer {
        padding: 4rem;
        line-height: 1.5 !important;
        letter-spacing: 0.05rem !important;
    }

    .footer__top {
        flex-direction: column;
    }

    .footer__left {
        width: 100%;
        max-width: 100%;
        margin-bottom: 2rem;
    }

    .footer__text {
        width: 100%;
        font-size: 0.9rem;
    }

    .footer__bottom {
        font-size: 0.8rem;
    }

    .footer__legal-links {
        justify-content: center;
    }

    .footer__legal-item {
        margin-left: 2rem;
        font-size: 0.8rem;
    }

    /* SECTION HERO */

    #section-hero {
        padding: 6rem 1rem;
    }

    #section-hero {
        height: 10vh;
    }

    .section-hero__text {
        font-size: 3rem !important;
    }

    /* CARD - BLOG */

    .blog-card__image {
        display: none;
    }

    .blog-card {
        height: 13rem;
    }

    .blog-card__title {
        font-size: 1.1rem;
        margin: 0;
        margin-bottom: 0.5rem;
        font-weight: 700;
        color: var(--dark-color);
    }

    /* CARD - SERVICE */

    .services-grid {
        padding: 0;
    }

    .service-card-name {
        padding: 0 1rem;
    }

    .service-card-image {
        display: none;
    }

    .service-dark-card {
        width: 20rem;
        height: 6rem;
    }

    .service-dark-name {
        padding: 0 1rem;
        font-size: 1.2rem;
    }

    .service-card-name {
        padding: 0 1rem;
        font-size: 1.1rem;
    }

    .service-dark-image {
        display: none;
    } 

    .service-card {
        width: 100%;
        height: 6rem;
        padding: 0.5rem;
    }

    /* CARD - MISSION VISION */

    .mv-section {
        padding: 0;
        margin: 2rem 0;
    }
    .mv-icon {
        display: none;
    }

    /* CTA - HEADER */

    .cta-header {
        padding: 4rem 0 4rem 0;
    }

    .cta-header__heading {
        margin-bottom: 1rem;
        font-size: 1.1rem;
    }

    .cta-header__text {
        font-size: 0.9rem;
    }

    /* CTA - FOOTER */

    .cta-footer {
        padding: 7rem 0;
    }

    .cta-footer__title {
        font-size: 1.1rem;
    }

    /* COMMON */

    .cta-footer__button {
        font-size: 1rem;
    }

}